import axios from 'axios';
import { setLoginSession, getLoginSession, clearLoginSession } from '../service/sessionManager';
import Auth from './auth';
import News from './news';
import Pr from './pr';
import Recruit from './recruit';

const aiblabApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

const auth = new Auth(aiblabApiClient);
const news = new News(aiblabApiClient);
const pr = new Pr(aiblabApiClient);
const recruit = new Recruit(aiblabApiClient);

export const aiblabApis = {
  auth,
  news,
  pr,
  recruit,
};

const savedLoginSession = getLoginSession();

if (savedLoginSession.isLogin) {
  aiblabApiClient.defaults.headers.common['Authorization'] = 'Bearer ' + savedLoginSession.accessToken;
}

/*
# 인증 오류(401) 인터셉트
- 인증 오류 시 저장된 refreshToken을 이용하여 토큰 갱신 시도
- 토큰 갱신 성공 시 세션에 저장하고 화면 리로드
- 토근 갱신 실패 시 로그인 화면으로 이동
*/

const getRefreshToken = async (userId, refreshToken) => {
  const response = await aiblabApiClient.post('user/refresh', {
    userId,
    refreshToken,
  });
  return response.data;
};

aiblabApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const savedLoginSession = getLoginSession();

      if (savedLoginSession.refreshToken) {
        console.log(savedLoginSession);
        getRefreshToken(savedLoginSession.loginUser, savedLoginSession.refreshToken)
          .then((response) => {
            setLoginSession(true, savedLoginSession.loginUser, response.accessToken, savedLoginSession.refreshToken);
            window.location.reload();
          })
          .catch(() => {
            clearLoginSession();
            window.location.href = '/login';
          });
      } else {
        clearLoginSession();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);
