export default class Recruit {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getRecruitList(pageNo, pageSize, keyword) {
    const response = await this.httpClient.get('announcement', {
      params: {
        pageNo,
        pageSize,
        keyword,
      },
    });

    return response;
  }

  async getRecruitById(announcementId) {
    const response = await this.httpClient.get(`announcement/${announcementId}`);

    return response.data.data;
  }

  async createRecruit(category, status, title, contents, hidden, userId) {
    const response = await this.httpClient.post('announcement/create', {
      category,
      status,
      title,
      contents,
      hidden,
      userId,
    });

    return response.data.data;
  }

  async updateRecruit(announcementId, category, status, title, contents, hidden, userId) {
    const response = await this.httpClient.put('announcement/update', {
      announcementId,
      category,
      status,
      title,
      contents,
      hidden,
      userId,
    });

    return response;
  }

  async deleteRecruit(announcementId) {
    const response = await this.httpClient.delete('announcement/delete', {
      data: {
        announcementId,
      },
    });

    return response;
  }
}
