import React, { useState } from 'react';
import Pagination from 'react-js-pagination';

export default function CustomPagination({ pageInfo, handleChangePage }) {
  const { pageNo, pageSize, totalRowCount } = pageInfo;
  const [cursor, setCursor] = useState(pageNo);

  return (
    <Pagination
      totalItemsCount={totalRowCount}
      itemsCountPerPage={pageSize}
      pageRangeDisplayed={10}
      activePage={cursor}
      innerClass="pagination"
      itemClass="page-item"
      linkClass="page-link"
      linkClassPrev="prev"
      linkClassNext="next"
      prevPageText=""
      nextPageText=""
      hideFirstLastPages={true}
      onChange={(activePage) => {
        setCursor(activePage);
        handleChangePage(activePage);
      }}
    />
  );
}
