import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Lnb({ lnbList }) {
  const { pathname } = useLocation();
  const targetPathname = pathname.split('/')[2];

  return (
    <ul className="lnb">
      {lnbList.map((item, index) => (
        <li key={index}>
          <Link to={item.path} className={item.path.includes(targetPathname) ? 'active' : ''}>
            <span>{item.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
