import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../asset/images/logo.png';
import { aiblabApis } from '../api/aiblab';
import { clearLoginSession, getLoginSession } from '../service/sessionManager';

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loginUser } = getLoginSession();

  const logout = () => {
    aiblabApis.auth
      .logout(loginUser)
      .then((response) => {
        clearLoginSession();
        navigate('/login');
      })
      .catch((error) => console.log(error.response));
  };

  return (
    <header className="header">
      <div className="inner">
        <h1 className="logo">
          <Link to="/info/news">
            <img src={logo} alt="아이브랩" />
          </Link>
        </h1>
        <button className="ico_btn navbar_toggle">
          <span className="menu_btn"></span>
        </button>
        <div className="navbar">
          <ul className="nav" id="gnb">
            <li>
              <Link to="/info/news" className={pathname.includes('/info') ? 'active' : ''}>
                <span>PR 관리</span>
              </Link>
            </li>
            <li>
              <Link to="/career/recruit" className={pathname.includes('/career') ? 'active' : ''}>
                <span>채용 관리</span>
              </Link>
            </li>
          </ul>
          <div className="btn_area">
            <button className="btn btn_y_md" onClick={logout}>
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
