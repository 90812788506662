import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { aiblabApis } from '../../api/aiblab';
import { setLoginSession } from '../../service/sessionManager';

export default function Login() {
  const idRef = useRef();
  const passwordRef = useRef();
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();

  const doLogin = () => {
    const id = idRef.current.value;
    const password = passwordRef.current.value;

    if (String(id).trim() == '' || String(password).trim() == '') {
      setIsValid(false);
      return;
    }

    aiblabApis.auth
      .login(id, password)
      .then((response) => {
        if (response.status === 200) {
          navigate('/info/news');
          return;
        }
        setIsValid(false);
      })
      .catch((error) => console.log(error));
  };

  const submitValues = (e) => {
    e.preventDefault();
    doLogin();
  };

  return (
    <div className="login_steps">
      <div className="title">로그인</div>
      <form className="form" onSubmit={submitValues}>
        <div className="form-group">
          <label htmlFor="userId">아이디</label>
          <input type="text" className="form-control" name="userId" ref={idRef} placeholder="아이디를 입력하세요" />
        </div>
        <div className="form-group">
          <label htmlFor="userPassword">비밀번호</label>
          <input
            type="password"
            className="form-control"
            name="userPassword"
            ref={passwordRef}
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        {isValid ? (
          ''
        ) : (
          <div className="message_box">
            <p className="message alert">
              이메일 또는 비밀번호를 다시 확인하세요. <br />
              등록되지 않은 이메일이거나, 이메일 또는 비밀번호를 잘못 입력하셨습니다.
            </p>
          </div>
        )}
        <div className="btn_area">
          <button type="submit" className="btn btn_wide btn_y_lg btn_cyan" onClick={doLogin}>
            로그인
          </button>
        </div>
      </form>
    </div>
  );
}
