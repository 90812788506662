export default class News {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getNewsList(pageNo, pageSize, keyword) {
    const response = await this.httpClient.get('news', {
      params: {
        pageNo,
        pageSize,
        keyword,
      },
    });
    return response;
  }

  async getNewsById(itemId) {
    const response = await this.httpClient.get(`news/${itemId}`);
    return response.data.data;
  }

  async createNews(title, contents, hidden, userId) {
    const response = await this.httpClient.post('news/create', {
      title,
      hidden,
      contents,
      userId,
    });
    return response.data.data;
  }

  async updateNews(newsId, title, contents, hidden, userId) {
    const response = await this.httpClient.put('news/update', {
      newsId,
      title,
      contents,
      hidden,
      userId,
    });
    return response;
  }

  async deleteNews(newsId) {
    const response = await this.httpClient.delete('news/delete', {
      data: {
        newsId,
      },
    });
    return response;
  }
}
