import React from 'react';
import { Outlet } from 'react-router-dom';
import Lnb from '../../components/Lnb';

export default function InfoPage() {
  const lnbList = [
    { title: '아이브랩 소식', path: '/info/news' },
    { title: '언론홍보', path: '/info/pr' },
  ];

  return (
    <>
      <Lnb lnbList={lnbList} />
      <Outlet />
    </>
  );
}
