import React from 'react';
import { Outlet } from 'react-router-dom';
import Lnb from '../../components/Lnb';

export default function CareerPage() {
  const lnbList = [{ title: '채용', path: '/career/recruit' }];

  return (
    <>
      <Lnb lnbList={lnbList} />
      <Outlet />
    </>
  );
}
