export function setLoginSession(isLogin, loginUser, accessToken, refreshToken) {
  const loginSession = {
    isLogin,
    loginUser,
    accessToken,
    refreshToken,
  };
  localStorage.setItem('loginSession', JSON.stringify(loginSession));
}

export function getLoginSession() {
  return localStorage.getItem('loginSession')
    ? JSON.parse(localStorage.getItem('loginSession'))
    : { isLogin: false, loginUser: null, accessToken: null, refreshToken: null };
}

export function clearLoginSession() {
  localStorage.clear();
}
