import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { aiblabApis } from '../../api/aiblab';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export default function NewsDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [item, setItem] = useState({});

  const handleLoadData = () => {
    aiblabApis.news
      .getNewsById(state.newsId)
      .then(async (response) => {
        if (response.contents) {
          const myIframe = document.getElementById('myIframe');
          const iframeDocument = myIframe.contentDocument;

          iframeDocument.open();

          await iframeDocument.write(response.contents);

          // 이미지 스타일링
          let images = iframeDocument.querySelectorAll('img');
          images.forEach(function (img) {
            img.style.maxWidth = '100%';
          });
          // 9:16 비율 동영상 스타일링
          let iframes = iframeDocument.querySelectorAll('iframe');
          iframes.forEach(function (iframe) {
            iframe.parentNode.style.cssText = 'position: relative; width: 100%; max-width: 100%; padding-top: 56.25%';
            iframe.style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';
          });
          // body 마진 제거
          iframeDocument.body.style.margin = 0;
          // 높이 지정
          myIframe.style.height = `${iframeDocument.body.offsetHeight}px`;

          iframeDocument.close();
        }
        setItem(response);
      })
      .catch((error) => console.log(error.response));
  };

  const deleteItem = () => {
    aiblabApis.news
      .deleteNews(state.newsId)
      .then((response) => {
        navigate('/info/news');
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <div className="main_contents">
        <div className="mc_header">
          <h3 className="title">아이브랩 소식 상세</h3>
        </div>
        <div className="mc_body">
          <div className="view_area">
            <div className="view_header">
              <h4 className="title">{item.title}</h4>
            </div>
            <div className="view_body">
              <div className="summary">
                <div className="main">
                  <p className="name">작성자 : {item.userId}</p>
                  <p className="date">작성일 : {item.createTime}</p>
                </div>
                <div className="option">
                  <p className="hidden">
                    <span>게시글 숨김</span>
                    {item.hidden ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </p>
                </div>
              </div>
              <iframe id="myIframe" width="100%" scrolling="no" />
            </div>
            <div className="view_footer">
              <div className="btn_area">
                <button type="button" className="btn" onClick={() => navigate('/info/news')}>
                  목록
                </button>
              </div>
              <div className="btn_area">
                <Link to="/info/news/edit" className="btn" state={{ item }}>
                  수정
                </Link>
                <button type="button" className="btn" onClick={deleteItem}>
                  삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
