import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { aiblabApis } from '../../api/aiblab';
import CustomPagination from '../../components/CustomPagination';
import { BiSearchAlt2 } from 'react-icons/bi';
import { AiFillEyeInvisible } from 'react-icons/ai';

export default function RecruitList() {
  const [items, setItems] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageNo: 1,
    pageSize: 10,
    totalRowCount: 10,
  });
  const { pageNo, pageSize, totalRowCount } = pageInfo;
  const [keyword, setKeyword] = useState('');

  const handleLoadData = () => {
    aiblabApis.recruit
      .getRecruitList(pageNo, pageSize, keyword)
      .then((response) => {
        if (response.status === 200) {
          setItems(response.data.items);
          setPageInfo(response.data.pagination);
        }
      })
      .catch((error) => console.log(error.response));
  };

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmitKeyword = (e) => {
    e.preventDefault();
  };

  const handleChangePage = (pageNo) => {
    setPageInfo((prevValues) => ({
      ...prevValues,
      pageNo,
    }));
  };

  useEffect(() => {
    handleLoadData();
  }, [pageNo, keyword]);

  return (
    <>
      <div className="main_contents">
        <div className="mc_header">
          <h3 className="title">채용</h3>
        </div>
        <div className="mc_body">
          <section>
            <div className="toolbar">
              <form className="input-group input-group-search" onSubmit={handleSubmitKeyword}>
                <input
                  type="search"
                  className="form-control"
                  name="keyword"
                  value={keyword}
                  onChange={handleChangeKeyword}
                  placeholder="제목으로 검색하기"
                />
                <button type="submit" className="ico_btn">
                  <BiSearchAlt2 />
                </button>
              </form>
            </div>
            <div className="table_area">
              <div className="table">
                <div className="table_row heading">
                  <div className="row_item num">No.</div>
                  <div className="row_item category">직군</div>
                  <div className="row_item title">제목</div>
                  <div className="row_item date">작성일</div>
                  <div className="row_item state">상태</div>
                </div>
                {items.length === 0 ? (
                  <div className="table_row">
                    <div className="row_item none">등록된 게시글이 없습니다.</div>
                  </div>
                ) : (
                  items.map((item, index) => (
                    <div className="table_row" key={index}>
                      <Link
                        to={`/career/recruit/:${item.id}`}
                        state={{ announcementId: item.announcementId }}
                        className="row_link"
                      >
                        <div className="row_item num">{totalRowCount - index - (pageNo - 1) * pageSize}</div>
                        <div className="row_item category">{item.category}</div>
                        <div className="row_item title">
                          <p className="ellipsis">{item.title}</p>
                          <p>{item.hidden === 1 ? <AiFillEyeInvisible /> : ''}</p>
                        </div>
                        <div className="row_item date">{item.createTime}</div>
                        <div className="row_item state">{item.status === 0 ? '진행중' : '채용종료'}</div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
              <CustomPagination pageInfo={pageInfo} handleChangePage={handleChangePage} />
              <div className="btn_area">
                <Link className="btn btn_black" to="/career/recruit/edit">
                  작성
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
