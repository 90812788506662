import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export function AppProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const [handleModal, setHandleModal] = useState(false);
  const hideModal = () => setHandleModal(false);
  const showModal = () => setHandleModal(true);

  const value = {
    isLoading: { loading, setLoading },
    handleModal: { handleModal, setHandleModal, hideModal, showModal },
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
// 앱 최상단에 <AppProvider></AppProvider> 로 감싸면
// useContext로 만들어진 함수를 원하는 위치에서 임포트하여 사용 가능

export const useLoadingContext = () => useContext(AppContext).isLoading;
export const useModalContext = () => useContext(AppContext).handleModal;
