import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { getLoginSession } from './service/sessionManager';
import Login from './pages/auth/Login';
import App from './App';
import NotFound from './components/notFound/NotFound';
import Main from './pages/Main';
import InfoPage from './pages/info/InfoPage';
import NewsList from './pages/info/NewsList';
import NewsDetail from './pages/info/NewsDetail';
import NewsEdit from './pages/info/NewsEdit';
import PrList from './pages/info/PrList';
import PrDetail from './pages/info/PrDetail';
import PrEdit from './pages/info/PrEdit';
import CareerPage from './pages/career/CareerPage';
import RecruitList from './pages/career/RecruitList';
import RecruitDetail from './pages/career/RecruitDetail';
import RecruitEdit from './pages/career/RecruitEdit';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Main />,
        loader: () => {
          const { isLogin } = getLoginSession();
          if (isLogin) {
            return redirect('/info/news');
          } else {
            return redirect('/login');
          }
        },
      },
      {
        path: 'info',
        element: <InfoPage />,
        children: [
          { path: 'news', element: <NewsList /> },
          { path: 'news/:newsId', element: <NewsDetail /> },
          { path: 'news/edit', element: <NewsEdit /> },
          { path: 'pr', element: <PrList /> },
          { path: 'pr/:prId', element: <PrDetail /> },
          { path: 'pr/edit', element: <PrEdit /> },
        ],
      },
      {
        path: 'career',
        element: <CareerPage />,
        children: [
          { path: 'recruit', element: <RecruitList /> },
          { path: 'recruit/:recruitId', element: <RecruitDetail /> },
          { path: 'recruit/edit', element: <RecruitEdit /> },
        ],
      },
    ],
  },
  { path: '/login', element: <Login /> },
]);

root.render(
  <AppProvider>
    <RouterProvider router={router} />
  </AppProvider>
);
