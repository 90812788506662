import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { aiblabApis } from '../../api/aiblab';
import { getLoginSession } from '../../service/sessionManager';

export default function PrEdit() {
  const navigate = useNavigate();
  const { loginUser } = getLoginSession();
  const { state } = useLocation();
  const [data, setData] = useState(
    state
      ? state.item
      : {
          directLink: 0,
          hidden: 0,
          userId: loginUser,
        }
  );

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setData((preValues) => ({
      ...preValues,
      [name]: value,
    }));
  };

  const handleChangeChecked = (e) => {
    const { name, checked } = e.target;
    checked
      ? setData((prev) => ({
          ...prev,
          [name]: 1,
        }))
      : setData((prev) => ({
          ...prev,
          [name]: 0,
        }));
  };

  const editorRef = useRef(null);
  const [editorText, setEditorText] = useState(data.contents || '');
  const categoryList = ['언론사', '메트로', '전자신문'];
  const [isHidden, setIsHidden] = useState(data.hidden === 1 || false);
  const [isDirectUrl, setIsDirectUrl] = useState(data.directLink === 1 || false);

  const handleCheckedHidden = (e) => {
    handleChangeChecked(e);
    e.target.checked ? setIsHidden(true) : setIsHidden(false);
  };

  const handleCheckedDirectUrl = (e) => {
    handleChangeChecked(e);
    e.target.checked ? setIsDirectUrl(true) : setIsDirectUrl(false);
    setData((prev) => ({
      ...prev,
      linkUrl: '',
    }));
  };

  const isValidCategory = data.category ? true : false;
  const isValidTitle = String(data.title).trim() !== '';
  const isValidUrl = String(data.linkUrl).startsWith('http://') || String(data.linkUrl).startsWith('https://');

  const createItem = () => {
    if (!isValidCategory) {
      alert('카테고리를 입력해주세요.');
      return;
    } else if (!data.title || !isValidTitle) {
      alert('제목을 입력해주세요.');
      return;
    } else if (isDirectUrl && !isValidUrl) {
      alert('URL을 입력하지 않았거나 유효한 URL이 아닙니다.');
      return;
    } else {
      aiblabApis.pr
        .createPr(
          data.category,
          data.directLink,
          data.linkUrl,
          data.title,
          editorRef.current.getContent(),
          data.hidden,
          data.userId
        )
        .then((response) => {
          navigate('/info/pr');
        })
        .catch((error) => console.log(error.response));
    }
  };

  const updateItem = () => {
    if (!isValidCategory) {
      alert('카테고리를 입력해주세요.');
      return;
    } else if (!data.title || !isValidTitle) {
      alert('제목을 입력해주세요.');
      return;
    } else if (isDirectUrl && !isValidUrl) {
      alert('URL을 입력하지 않았거나 유효한 URL이 아닙니다.');
      return;
    } else {
      aiblabApis.pr
        .updatePr(
          data.promotionId,
          data.category,
          data.directLink,
          data.linkUrl,
          data.title,
          editorRef.current.getContent(),
          data.hidden,
          loginUser
        )
        .then((response) => {
          navigate('/info/pr');
        })
        .catch((error) => console.log(error.response));
    }
  };

  return (
    <div className="main_contents">
      <div className="mc_header">
        <h3 className="title">언론홍보 {!state ? '작성' : '수정'}</h3>
        <p className="sub">
          <span className="asterisk"></span> 는 필수 입력값입니다.
        </p>
      </div>
      <div className="mc_body">
        <div className="view_area">
          <div className="view_body">
            <form className="form">
              <div className="column option">
                <div className="form-group">
                  <label className="asterisk">카테고리</label>
                  <select className="form-select" name="category" value={data.category} onChange={handleChangeValue}>
                    <option value="">선택</option>
                    {categoryList.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <div className="form-check form-switch">
                    <label htmlFor="directLink" className="form-check-label asterisk">
                      다이렉트 URL
                    </label>
                    <input
                      type="checkbox"
                      id="directLink"
                      name="directLink"
                      className="form-check-input"
                      onChange={handleCheckedDirectUrl}
                      checked={isDirectUrl}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check form-switch">
                    <label htmlFor="hidden" className="form-check-label asterisk">
                      게시글 숨김
                    </label>
                    <input
                      type="checkbox"
                      id="hidden"
                      name="hidden"
                      className="form-check-input"
                      onChange={handleCheckedHidden}
                      checked={isHidden}
                    />
                  </div>
                </div>
              </div>
              <div className="column main">
                <div className="form-group">
                  <label htmlFor="title" className="asterisk">
                    제목
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={data.title || ''}
                    onChange={handleChangeValue}
                    placeholder="제목을 입력해 주세요."
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="linkUrl" className={isDirectUrl ? 'asterisk' : ''}>
                    URL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkUrl"
                    name="linkUrl"
                    value={data.linkUrl || ''}
                    onChange={handleChangeValue}
                    placeholder="프로토콜을 포함한 URL을 입력해 주세요."
                    disabled={data.directLink === 1 ? '' : 'disabled'}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contents">내용</label>
                  <Editor
                    apiKey="0hmzew33tljccs6fe7dmkha7kygskohg0es2ekjfrgitvbni"
                    init={{
                      language: 'ko_KR',
                      height: 500,
                      placeholder: '내용을 입력해 주세요.',
                      resize: false,
                      images_file_types: 'jpg,png,jpeg,svg,webp,gif',
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size:14px; }',
                    }}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(newValue, editor) => {
                      setEditorText(newValue);
                    }}
                    value={editorText}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="view_footer">
            <div className="btn_area">
              {!state ? (
                <button type="submit" className="btn btn_black" onClick={createItem}>
                  작성
                </button>
              ) : (
                <button type="submit" className="btn btn_black" onClick={updateItem}>
                  수정
                </button>
              )}
              <Link className="btn" to="/info/pr">
                취소
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
