export default class Pr {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getPrList(pageNo, pageSize, keyword) {
    const response = await this.httpClient.get('promotion', {
      params: {
        pageNo,
        pageSize,
        keyword,
      },
    });

    return response;
  }

  async getPrById(promotionId) {
    const response = await this.httpClient.get(`promotion/${promotionId}`);

    return response.data.data;
  }

  async createPr(category, directLink, linkUrl, title, contents, hidden, userId) {
    const response = await this.httpClient.post('promotion/create', {
      category,
      directLink,
      linkUrl,
      title,
      contents,
      hidden,
      userId,
    });

    return response.data.data;
  }

  async updatePr(promotionId, category, directLink, linkUrl, title, contents, hidden, userId) {
    const response = await this.httpClient.put('promotion/update', {
      promotionId,
      category,
      directLink,
      linkUrl,
      title,
      contents,
      hidden,
      userId,
    });

    return response;
  }

  async deletePr(promotionId) {
    const response = await this.httpClient.delete('promotion/delete', {
      data: {
        promotionId,
      },
    });

    return response;
  }
}
