import { setLoginSession } from '../service/sessionManager';

export default class Auth {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async login(userId, userPw) {
    const response = await this.httpClient.post('user/login', {
      userId,
      userPw,
    });
    if (response.status === 200) {
      const loginData = response.data;
      setLoginSession(true, loginData.userId, loginData.accessToken, loginData.refreshToken);
      this.httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
    }
    return response;
  }

  async logout(userId) {
    const response = await this.httpClient.post('user/logout', {
      userId,
    });
    return response.data;
  }
}
