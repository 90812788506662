import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { aiblabApis } from '../../api/aiblab';
import { getLoginSession } from '../../service/sessionManager';

export default function NewsEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState(
    state
      ? state.item
      : {
          hidden: 0,
        }
  );
  const { loginUser } = getLoginSession();
  const [isHidden, setIsHidden] = useState(data.hidden === 1 || false);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChangeChecked = (e) => {
    const { name, checked } = e.target;
    checked
      ? setData((prev) => ({
          ...prev,
          [name]: 1,
        }))
      : setData((prev) => ({
          ...prev,
          [name]: 0,
        }));
  };

  const handleCheckedHidden = (e) => {
    handleChangeChecked(e);
    e.target.checked ? setIsHidden(true) : setIsHidden(false);
  };

  const editorRef = useRef(null);
  const [editorText, setEditorText] = useState(data.contents || '');
  const isValidTitle = String(data.title).trim() !== '';

  const createItem = () => {
    if (!data.title || !isValidTitle) {
      alert('제목을 입력하세요.');
    } else {
      aiblabApis.news
        .createNews(data.title, editorRef.current.getContent(), data.hidden, loginUser)
        .then((response) => {
          navigate('/info/news');
        })
        .catch((error) => console.log(error.response));
    }
  };

  const updateItem = () => {
    if (!data.title || !isValidTitle) {
      alert('제목을 입력하세요.');
    } else {
      aiblabApis.news
        .updateNews(data.newsId, data.title, editorRef.current.getContent(), data.hidden, loginUser)
        .then((response) => {
          navigate('/info/news');
        })
        .catch((error) => console.log(error.response));
    }
  };

  return (
    <div className="main_contents">
      <div className="mc_header">
        <h3 className="title">아이브랩 소식 {!state ? '작성' : '수정'}</h3>
        <p className="sub">
          <span className="asterisk"></span> 는 필수 입력값입니다.
        </p>
      </div>
      <div className="mc_body">
        <div className="view_area">
          <div className="view_body">
            <form className="form">
              <div className="column option">
                <div className="form-group">
                  <div className="form-check form-switch">
                    <label htmlFor="hidden" className="form-check-label asterisk">
                      게시글 숨김
                    </label>
                    <input
                      type="checkbox"
                      id="hidden"
                      name="hidden"
                      className="form-check-input"
                      onChange={handleCheckedHidden}
                      checked={isHidden}
                    />
                  </div>
                </div>
              </div>
              <div className="column main">
                <div className="form-group">
                  <label className="asterisk">제목</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={data.title || ''}
                    onChange={handleChangeValue}
                    placeholder="제목을 입력해 주세요."
                  />
                </div>
                <div className="form-group">
                  <label>내용</label>
                  <Editor
                    apiKey="0hmzew33tljccs6fe7dmkha7kygskohg0es2ekjfrgitvbni"
                    init={{
                      language: 'ko_KR',
                      height: 500,
                      placeholder: '내용을 입력해 주세요.',
                      resize: false,
                      images_file_types: 'jpg,png,jpeg,svg,webp,gif',
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size:14px; }',
                      // setup: function (editor) {
                      //   editor.on('init', function (e) {
                      //     editor.setContent(editorText);
                      //   });
                      // },
                    }}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(newValue, editor) => {
                      setEditorText(newValue);
                    }}
                    value={editorText}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="view_footer">
            <div className="btn_area">
              {!state ? (
                <button type="submit" className="btn btn_black" onClick={createItem}>
                  작성
                </button>
              ) : (
                <button type="submit" className="btn btn_black" onClick={updateItem}>
                  수정
                </button>
              )}
              <Link to="/info/news" className="btn">
                취소
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
